import React from "react";

function _isContains(JSON, value) {
    
    var hasMatch = false;
    for (var index = 0; index < JSON.length; ++index) {
        var role = JSON[index];
        console.log("role.displayName", role.displayName);
        console.log("value",value);
        if (role.displayName == value) {
            hasMatch = true;
            break;
        }
    }
    console.log("hasMatch", hasMatch);
    return hasMatch;
}

export default _isContains