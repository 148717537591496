import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../component/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { Pagination } from "../../../../component/Common/pagination";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../component/Common/Loader";
import { FaSearch } from "react-icons/fa";
import { ReportData } from "../../../../redux/feature/translation/report/ReportDataSlice";

export const ExportReportById = () => {
  const ITEM_PER_PAGE = 10;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [sampleData, setSampleData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (id) {
      const request = {
        type: "exportReport",
        id: id,
        download: false,
      };
      setLoadingData(true);
      dispatch(ReportData(request)).then((action) => {
        if (action.payload?.statusCode === 200 && action.payload.result) {
          const data = action.payload.result.value.map((obj) => ({
            entryId: String(obj.entry_id),
            contentType: String(obj.content_type),
            title: String(obj.title),
            status: obj.status,
          }));
          setSampleData(data);
          setTotalPage(Math.ceil(data.length / ITEM_PER_PAGE));
          setPaginatedData(data.slice(0, ITEM_PER_PAGE)); // Assuming you want to show first page initially
          setCurrentPage(1); // Reset to first page when data changes
        }
        setLoadingData(false);
      });
    }
  }, [id, dispatch]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredData = sampleData.filter(
    (e) =>
      e.entryId.toLowerCase().includes(filter.toLowerCase()) ||
      e.title.toLowerCase().includes(filter.toLowerCase()) ||
      e.contentType.toLowerCase().includes(filter.toLowerCase())
  );

  useEffect(() => {
    const start = (currentPage - 1) * ITEM_PER_PAGE;
    const end = start + ITEM_PER_PAGE;
    setPaginatedData(filteredData.slice(start, end));
  }, [currentPage, filteredData]);

  const onPageChange = (index) => {
    setCurrentPage(index);
    const start = (index - 1) * ITEM_PER_PAGE;
    const end = start + ITEM_PER_PAGE;
    setPaginatedData(filteredData.slice(start, end));
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ marginBottom: '10px' }}
              >
                <div className="card-header" style={{ marginRight: 'auto' }}>Export Report Data</div>
                <div className="filters mr-1 mt-2" style={{ marginLeft: 'auto' }}>
                  <div className="input-group" style={{ width: '450px' }}>
                    <span className="input-group-text" style={{ backgroundColor: '#e9ecef', borderRight: '0' }}>
                      <FaSearch />
                    </span>
                    <input
                      type="text"
                      name="filter"
                      className="form-control mr-3"
                      placeholder="Filter by Entry Id, or Name/Title"
                      value={filter}
                      onChange={handleFilterChange}
                      style={{ borderLeft: '0' }}
                    />
                  </div>
                </div>
              </div>
              <hr className="hr" />
              <div className="p-2" />
              <div className="mx-4">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr className="text-black text-red">
                        <th scope="col">Entry Id</th>
                        <th scope="col">Content Type</th>
                        <th scope="col">Name/Title</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingData ? (
                        <Loader />
                      ) : (
                        paginatedData.map((data) => (
                          <tr key={data.entryId}>
                            <td>{data.entryId}</td>
                            <td>{data.contentType}</td>
                            <td>{data.title}</td>
                            <td>{data.status}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  totalPage={totalPage}
                  itemPerPage={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
