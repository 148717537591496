import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../../../config/config";

export const stateRequest = createAsyncThunk(
    "stateChange",
    async (data, { rejectWithValue }) => {
        //  
        const token = window.localStorage.getItem("idToken");
        const response = await fetch(
            config.STATE_CHANGE_API,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+token
                },
                body: JSON.stringify(data),
            }
        );
        try {
            const result = response.json();
             
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


const stateChangeSlice = createSlice({
    name: "stateChange",
    initialState: {
        loading: false,
        error: false,
        data: {}
    },
    extraReducers: {
        [stateRequest.pending]: (state) => {
            state.loading = true;
        },
        [stateRequest.fulfilled]: (state, action) => {
            
            if(action.payload.statusCode === 200){
                 
                state.loading = false;
                state.data = action.payload.body;
            }else{
                state.error = true;
                state.loading = false;
                state.data = action.payload;
            }
          
        },
        [stateRequest.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }

});

export default stateChangeSlice.reducer;