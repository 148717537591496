import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const adGroupToken = createAsyncThunk(
    "token",
    async (values, { rejectWithValue }) => {

        let requestBody = {
            "grant_type": process.env.REACT_APP_GRANT_TYPE,
            "client_id": process.env.REACT_APP_CLIENT_ID,
            "client_secret": process.env.REACT_APP_CLIENT_SECRET,
            "scope": process.env.REACT_APP_SCOPE
        };
        const response = await fetch(
            'https://13gazc26y7.execute-api.us-east-2.amazonaws.com/prod/token',
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify(requestBody),
            }
        );
        try {
            const result = response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
         
    }
);
const adGroupSlice = createSlice({
    name: "adGroup",
    initialState: {
        loading: false,
        data: [],
        error: '',
        isSuccess: ''
    },
    extraReducers: {
        [adGroupToken.pending]: (state) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [adGroupToken.fulfilled]: (state, action) => {
            state.loading = false;
            state.isSuccess = true;
            state.data.push(action.payload);
        },
        [adGroupToken.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isSuccess = false;
        }
    }
})
export default adGroupSlice.reducer