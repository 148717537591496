import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from '../../../config/config'

export const snapshotRequest = createAsyncThunk(
    "snapshot",
    async (values, { rejectWithValue }) => {
        const token = window.localStorage.getItem("idToken");
        const response = await fetch(
            config.SNAPSHOT_API,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+token
                },
                body: JSON.stringify(values),
            }
        );

        try {

            const result = response.json();

            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
const snapshotRequestSlice = createSlice({
    name: "snapshot",
    initialState: {
        loading: false,
        error: '',
        isSuccess: false,
        data: [], 
        isError:false
    },
    extraReducers: {
        [snapshotRequest.pending]: (state) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [snapshotRequest.fulfilled]: (state, action) => {


            if (action.payload.statusCode === 200) {
                state.loading = false;
                state.isError=false;
                state.error = '';
                state.isSuccess = true;
                state.data.push(action.payload);
            } else if(action.payload.statusCode === 500 ){
                state.loading = false;
                state.isError=true;
                state.error = "SERVER ERROR"
                state.isSuccess = false;
                state.data.push(action.payload);
            }
            else {
                state.loading = false;
                state.isError=true;
                state.error = action.payload;
                state.isSuccess = false;
                state.data.push(action.payload);
            }


        },
        [snapshotRequest.rejected]: (state, action) => {
            state.loading = false;
            state.isError=false;
            state.error = action.payload;
            state.isSuccess = false;
        }
    }





})

export default snapshotRequestSlice.reducer