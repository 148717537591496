import React, { forwardRef, useRef, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/snapshotRequest.css";
import { convert } from '../../helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { snapshotRequest } from '../../redux/feature/snapshot/requestSlice';
import Breadcrumb from '../../component/Breadcrumb';
import Success from '../../model/success';
import SomethingWrong from '../../model/somethingWrong';
import Error from '../../model/error';
import convertDate from '../../component/utils/helper';
import { toast } from 'react-toastify';
import errorToast from '../../component/utils/errorToast';
import Loader from '../../component/Common/Loader';
import moment from 'moment/moment';

const SnapshotRequest = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const originalDAte = new Date();
    const [startDate, setStartDate] = useState(originalDAte);
    const [endDate, setEndDate] = useState(originalDAte);
    const callRef = useRef();
    const callRefEnd = useRef();
    const [company, setCompany] = useState("Lilly");
    const [country, setCountry] = useState("United States");
    const [language, setLanguage] = useState("English");
    const [entryId, setEntryId] = useState('');
    const [submitBtn, setSubmitBtn] = useState(false);

    const disptach = useDispatch();
    const userData = useSelector((state) => state.auth.userInfo);
    const responseSnapshot = useSelector((state) => state.snapshotRequest);


    const onChangeCompany = (e) => {
        setCompany((prevState) => (e.target.value));
        if (e.target.value.trim().length < 1) {
            setSubmitBtn(false)
        } else {
            setSubmitBtn(true)
        }
    }

    const onChangeCountry = (e) => {
        setCountry((prevState) => (e.target.value));
        if (e.target.value.trim().length < 1) {
            setSubmitBtn(false)
        } else {
            setSubmitBtn(true)
        }
    }


    const onChangeLanguage = (e) => {
        setLanguage((prevState) => (e.target.value));
        if (e.target.value.trim().length < 1) {
            setSubmitBtn(false)
        } else {
            setSubmitBtn(true)
        }
    }

    const onChangeEntryId = (e) => {
        setEntryId((prevState) => (e.target.value));
        if (e.target.value.trim().length < 1) {
            setSubmitBtn(false)
        } else {
            setSubmitBtn(true)
        }
    }


    const handleSubmit = () => {

        if (company == null && company == undefined) {
            errorToast("Mandatory fields must be filled in")
        }
        else if (country == null && country == undefined) {
            errorToast("Mandatory fields must be filled in")
        } else if (language == null && language == undefined) {
            errorToast("Mandatory fields must be filled in");
        }
        else if (entryId == '') {
            errorToast("Mandatory fields must be filled in");
        }
        else if (startDate == null && startDate == undefined) {
            errorToast("Mandatory fields must be filled in")
        }
        else if (endDate == null && endDate == undefined) {
            errorToast("Mandatory fields must be filled in")
        } else {

            const snapshot_request = {

                "company": company,
                "contentful_entry_id": entryId,
                "country": country,
                "end_date": convertDate(endDate),
                "language": language,
                "start_date": convertDate(startDate),
                "email_id": userData.username,
                "first_name": userData.name,
                "global_id": userData.idTokenClaims.employee_id,
                "last_name": userData.name,
                "spaceId": "0fxwiwy6a9kp",
                "system_logon_id": userData.idTokenClaims.uid

            }

            disptach(snapshotRequest(snapshot_request))
        }
    }

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            className='form-control'
            placeholder="YYYY-MM-DD"
        ></input>
    ));

    const DateCustomInputEnd = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            className='form-control'
            placeholder="YYYY-MM-DD"
        ></input>
    ));

    return (
        <div className="dashboard-ecommerce">

            {
                responseSnapshot.loading ? <Loader /> :
                    <div className="container-fluid dashboard-content ">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Breadcrumb />

                                {
                                    responseSnapshot.isSuccess && <Success isOpen={responseSnapshot.isSuccess} message={responseSnapshot.data[0].body} />
                                }
                                {
                                    responseSnapshot.isError && <Error isOpen={true} response={responseSnapshot.error} />
                                }

                                {/* Snapshot Content */}
                                <div className='widget'>
                                    <div className='card-header'>
                                        Snapshot Request Form
                                    </div>
                                    <hr className="hr" />

                                    <form>
                                        <div className="form-row">
                                            <div className="col">
                                                <h6>Company<span className="mandatory">*</span></h6>
                                                <label className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="radio-inline" value="Lilly" onChange={onChangeCompany} className="custom-control-input" defaultChecked /><span className="custom-control-label">Lilly</span>
                                                </label>
                                                <label className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="radio-inline" value="Elanco" onChange={onChangeCompany} className="custom-control-input" /><span className="custom-control-label" >Elanco</span>
                                                </label>

                                            </div>

                                            <div className="col">
                                                <h6>Country<span className="mandatory">*</span></h6>
                                                <select className="form-control" id="input-select" placeholder='Select Country' onChange={onChangeCountry} defaultValue={country}>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Bosnia">Bosnia</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="Dutch Antilles / Curacao">Dutch Antilles / Curacao</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran">Iran</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Ivory Coast">Ivory Coast</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan/Palestine">Jordan/Palestine</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Korea">Korea</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Macedonia">Macedonia</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Moldavia">Moldavia</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russia</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tanzania">Tanzania</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Vietnam</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>

                                            </div>
                                            <div className="col">
                                                <h6>Language<span className="mandatory">*</span></h6>
                                                <select className="form-control" id="input-select" onChange={onChangeLanguage} defaultValue={language}>
                                                    <option value="Ararbic">Ararbic</option>
                                                    <option value="Bulgarian">Bulgarian</option>
                                                    <option value="Chinese (Simplified)">Chinese (Simplified)</option>
                                                    <option value="Chinese (Traditional)">Chinese (Traditional)</option>
                                                    <option value="Czech">Czech</option>
                                                    <option value="Dutch">Dutch</option>
                                                    <option value="English">English</option>
                                                    <option value="French">French</option>
                                                    <option value="German">German</option>
                                                    <option value="Greek">Greek</option>
                                                    <option value="Hungarian">Hungarian</option>
                                                    <option value="Indonesian">Indonesian</option>
                                                    <option value="Italian">Italian</option>
                                                    <option value="Japanese">Japanese</option>
                                                    <option value="Korean">Korean</option>
                                                    <option value="Polish">Polish</option>
                                                    <option value="Portuguese">Portuguese</option>
                                                    <option value="Romanian">Romanian</option>
                                                    <option value="Russian">Russian</option>
                                                    <option value="Slovakian">Slovakian</option>
                                                    <option value="Spanish (Mexico)">Spanish (Mexico)</option>
                                                    <option value="Spanish (Spain)">Spanish (Spain)</option>
                                                    <option value="Thai">Thai</option>
                                                    <option value="Turkish">Turkish</option>
                                                    <option value="Vietnamese">Vietnamese</option>
                                                </select>

                                            </div>
                                            <div className="col">
                                                <h6>Contentful Entry ID<span className="mandatory">*</span></h6>
                                                <input type='text' className='form-control' onChange={onChangeEntryId} placeholder='Please enter Contentful id' />

                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <h6>Start Date (YYYY-MM-DD 00:00:00 UTC)<span className="mandatory">*</span></h6>
                                                <DatePicker
                                                    ref={callRef}
                                                    selected={startDate}
                                                    shouldCloseOnSelect={false}
                                                    peekNextMonth
                                                    customInput={<DateCustomInput />}
                                                    showMonthDropdown
                                                    onChange={(date) => setStartDate(date)}
                                                    maxDate={new Date()}
                                                    autocomplete={false}
                                                    adjustDateOnChange
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    yearDropdownItemNumber={10}
                                                    scrollableMonthYearDropdown={true}
                                                    dateFormat="yyyy-MM-dd"
                                                    

                                                >
                                                    <div className='button-footer'>
                                                        <button onClick={() => {
                                                            setStartDate(originalDAte)
                                                            callRef.current.setOpen(false)
                                                        }} className='btn-cancel-datepicker'>Cancel</button>
                                                        <button onClick={() => {
                                                            callRef.current.setOpen(false)
                                                        }} className='btn-ok-datepicker'>ok</button>
                                                    </div>
                                                </DatePicker>

                                            </div>
                                            <div className="col">
                                                <h6>End Date (YYYY-MM-DD 00:00:00 UTC)<span className="mandatory">*</span></h6>
                                                <DatePicker
                                                    ref={callRefEnd}
                                                    selected={endDate}
                                                    shouldCloseOnSelect={false}
                                                    peekNextMonth
                                                    customInput={<DateCustomInputEnd />}
                                                    showMonthDropdown
                                                    onChange={(date) => setEndDate(date)}
                                                    showYearDropdown
                                                    maxDate={new Date()}
                                                    autocomplete={false}
                                                    adjustDateOnChange
                                                    dropdownMode="select"
                                                    yearDropdownItemNumber={20}
                                                    scrollableMonthYearDropdown={true}
                                                    dateFormat="yyyy-MM-dd"

                                                >
                                                    <div className='button-footer'>
                                                        <button onClick={() => {
                                                            setEndDate(originalDAte)
                                                            callRefEnd.current.setOpen(false)
                                                        }} className='btn-cancel-datepicker'>Cancel</button>
                                                        <button onClick={() => {
                                                            callRefEnd.current.setOpen(false)
                                                        }} className='btn-ok-datepicker'>ok</button>
                                                    </div>
                                                </DatePicker>

                                            </div>
                                            <div className="col"> </div>
                                            <div className="col"></div>
                                        </div>
                                    </form>

                                    <hr className="hrBottom" />

                                    <div className="action-button">
                                        <a href="#" onClick={() => window.location.reload()} className="btn btn-outline-primary">Cancel</a>
                                        <a href="#" onClick={handleSubmit} className={`${submitBtn == true ? "btn btn-export" : "btn btn-primary"}`}>Submit</a>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </div >
    )
}

export default SnapshotRequest