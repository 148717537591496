import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const contextRequestForm = createAsyncThunk(
    "contextFile",
    async (values, { rejectWithValue }) => {
        console.log("values context file ==>", JSON.stringify(values));
        const token = window.localStorage.getItem("idToken");
        const response = await fetch(
            "https://elwzoc3j4k.execute-api.us-east-2.amazonaws.com/translation/Context/Context-Request-Form",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+token
                },
                body: JSON.stringify(values),
            }
        );

        try {
        
            const result = await response.json();
            console.log("result", result);
            return {
                statusCode: 200,
                message: "success",
                requestId: result.requestId
              };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
console.log("contextRequestForm", contextRequestForm);
const contextRequestFormSlice = createSlice({
    name: "contextFile",
    initialState: {
        loading: false,
        error: '',
        isSuccess: false,
        data: [], 
        isError:false
    },
    extraReducers: {
        [contextRequestForm.pending]: (state) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [contextRequestForm.fulfilled]: (state, action) => {
            console.log("context file action", action.payload);

            if (action.payload.statusCode === 200) {
                state.loading = false;
                state.isError=false;
                state.error = '';
                state.isSuccess = true;
                state.data.push(action.payload);
            } else if([2001, 2002, 2003, 2004].includes(action.payload.statusCode)){
                state.loading = false;
                state.isError=true;
                state.error = "SERVER ERROR"
                state.isSuccess = false;
                state.data.push(action.payload);
            }
            else {
                state.loading = false;
                state.isError=true;
                state.error = action.payload;
                state.isSuccess = false;
                state.data.push(action.payload);
            }


        },
        [contextRequestForm.rejected]: (state, action) => {
            state.loading = false;
            state.isError=false;
            state.error = action.payload;
            state.isSuccess = false;
        }
    }





})

export default contextRequestFormSlice.reducer